'use client'
import { getPublicConfigVariable } from '@lib/utils/getConfigVariable'
import { getIndexFromLocale } from '@lib/utils/searchEngineHelpers'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import { history } from 'instantsearch.js/es/lib/routers'
import { usePathname } from 'next/navigation'
import { createContext, useCallback, useEffect, useState } from 'react'
import { InstantSearchNext } from 'react-instantsearch-nextjs'
import { useAnalytics } from './analyticsContext'

const SEARCH_API_URL = getPublicConfigVariable('SEARCH_API_URL')
const SEARCH_API_KEY = getPublicConfigVariable('SEARCH_API_KEY')

const { searchClient } = instantMeiliSearch(SEARCH_API_URL, SEARCH_API_KEY, {
  placeholderSearch: false,
  primaryKey: 'id',
  meiliSearchParams: {
    attributesToSearchOn: [
      'books.title',
      'books.subtitle',
      'books.description',
      'authors.primaryRoles.fullName',
      'books.fullIsbn',
      'books.shortIsbn'
    ],
    showRankingScore: true,
    attributesToCrop: ['description'],
    cropLength: 30
  }
})

const SEARCH_INITIAL_STATE = ''

export type SearchContextProps = {
  searchValue: string
  setSearchValue: (value: string) => void
}

export const SearchContext = createContext<SearchContextProps>({
  searchValue: SEARCH_INITIAL_STATE,
  setSearchValue: () => {}
})

export const SearchProvider = ({ children, locale }: { children: React.ReactNode; locale: string }) => {
  const indexName = getIndexFromLocale(locale)
  const pathname = usePathname()
  const analytics = useAnalytics()
  const [searchValue, setSearchValue] = useState(SEARCH_INITIAL_STATE)

  const setSearchValueFn = useCallback(
    (value: string) => {
      setSearchValue((prev) => {
        if (prev === SEARCH_INITIAL_STATE) {
          analytics.trackEvent('SEARCH_STARTED', {})
        }

        return value
      })
    },
    [analytics]
  )

  // Clear search value when not on search page
  useEffect(() => {
    if (!pathname.includes('/search') && searchValue !== SEARCH_INITIAL_STATE) {
      setSearchValue((prev) => {
        analytics.trackEvent('SEARCH_ENDED', {
          searchQuery: prev
        })
        return SEARCH_INITIAL_STATE
      })
    }
  }, [pathname])

  return (
    <SearchContext.Provider value={{ searchValue, setSearchValue: setSearchValueFn }}>
      <InstantSearchNext
        searchClient={searchClient}
        routing={{
          router: {
            cleanUrlOnDispose: false,
            createURL: ({ location, routeState }) => {
              const SEARCH_PATH_REGEX = /\/search(?:\/|$)/

              if (!SEARCH_PATH_REGEX.test(location.pathname)) {
                return location.href
              }
              // Only mutate URL if on /search page
              return history().createURL(routeState)
            }
          },
          stateMapping: {
            stateToRoute(uiState) {
              const indexUiState = uiState[indexName]
              if (!indexUiState || !indexUiState.page) return uiState

              const { page, ...rest } = indexUiState

              return {
                [indexName]: {
                  ...rest
                }
              }
            },
            routeToState(routeState) {
              const indexUiState = routeState[indexName]
              if (!indexUiState || !indexUiState.page) return routeState
              const { page, ...rest } = indexUiState

              return {
                [indexName]: {
                  ...rest
                }
              }
            }
          }
        }}
        indexName={indexName}
        future={{ preserveSharedStateOnUnmount: true }}
      >
        {children}
      </InstantSearchNext>
    </SearchContext.Provider>
  )
}
